import React from 'react';
import { Helmet } from 'react-helmet';

import 'normalize.css'
import '../styles/index.css';

import logo from '../images/icon/logo/white.svg'
import appStore from '../images/app-store.svg'

import Copyright from '../components/Copyright'

function Index() {
  return (
    <div id="home">
      <Helmet>
        <title>REGYM - light and powerful App for Personal Trainers</title>
      </Helmet>
      <div className="landing-bg">
        <img src={logo} alt="proFIT" className="logo"/>
      </div>
      <footer>
        <div className="coming">
          <span className="title">
            COMING SOON IN
          </span>
          <img src={appStore} alt="App Store" className="app-store"/>
        </div>
        <Copyright />
      </footer>
    </div>
  );
}

export default Index;
